import React from 'react';

const Access: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M112.798 96.47c1.413 6.573 2.112 12.667 4.091 18.313 3.086 8.818 7.106 17.276 10.624 25.985 2.678 6.627.471 12.902-5.167 15.155-5.904 2.356-11.432-.298-14.385-7.067-2.481-5.654-4.813-11.371-7.232-17.056a10.83 10.83 0 00-.942-1.359c-2.623 6.282-5.08 12.258-7.656 18.211-3.031 7.012-8.763 9.729-14.794 7.114-5.544-2.403-7.507-8.591-4.774-15.32 3.392-8.324 7.2-16.491 10.208-24.956 2.05-5.835 3-12.054 4.625-18.902C78.758 95.245 71.62 94.232 64.514 93c-6.431-1.099-9.917-5.897-8.99-11.936.886-5.858 5.362-9.423 11.668-8.732 9.854 1.037 19.717 3.33 29.564 3.275 11.778-.071 23.47-2.09 35.21-3.197 7.137-.675 11.778 2.678 12.563 8.945.786 6.266-2.748 10.57-9.862 11.779-6.667 1.162-13.357 2.057-21.869 3.337zm-12.807 19.17c4.491 10.727 8.637 20.503 12.681 30.288 1.375 3.282 2.961 6.596 7.327 4.853 4.633-1.853 3.494-5.497 2.033-8.992-3.588-8.638-6.768-17.48-10.852-25.914-3.847-7.931-2.473-15.964-2.63-24.453 8.473-1.147 16.443-2.207 24.405-3.306 3.683-.51 6.777-1.79 5.936-6.376-.785-4.131-3.549-4.398-7.239-3.927-10.209 1.359-20.472 3.243-30.703 3.141-11.182-.07-22.332-2.261-33.53-3.14-1.94-.158-4.868 1.153-5.795 2.708-.785 1.359-.094 4.712 1.155 5.906 1.382 1.342 4.114 1.452 6.282 1.79 7.373 1.139 14.77 2.136 22.245 3.196.48 9.502.785 18.281-3.478 26.817-4.029 8.01-7.068 16.491-10.436 24.846-1.24 3.079-1.178 6.008 2.356 7.578 3.698 1.626 5.575-.746 7.067-3.589.604-1.154.958-2.434 1.468-3.643 3.793-8.874 7.546-17.834 11.708-27.783z"
    ></path>
    <path
      fill="currentColor"
      d="M100.126 72.653a14.707 14.707 0 01-14.998-14.386c-.228-8.065 6.84-15.109 15.029-15.03a15.07 15.07 0 0114.708 14.786 14.691 14.691 0 01-14.739 14.63zm9.281-14.606a9.419 9.419 0 00-9.399-9.447 9.423 9.423 0 109.399 9.447z"
    ></path>
    <path
      fill="currentColor"
      d="M100 177a77 77 0 01-75.526-92.039 77.006 77.006 0 0160.522-60.494 76.995 76.995 0 0179.041 32.782 77.006 77.006 0 0112.955 42.794A77.078 77.078 0 01100 177zm0-148.322a71.352 71.352 0 00-65.923 44.05 71.362 71.362 0 0015.468 77.765 71.347 71.347 0 0077.761 15.469 71.357 71.357 0 0044.048-65.926A71.455 71.455 0 00100 28.678z"
    ></path>
  </svg>
);

export default Access;
