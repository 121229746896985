import React from 'react';

const Caret: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41" fill="none" {...props}>
    <circle cx="20.5" cy="20.5" r="20.5" fill="#fff"></circle>
    <path fill="#000" d="M26.749 20.874L17.875 12v17.748l8.874-8.874z"></path>
  </svg>
);

export default Caret;
