import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

import type { ReactElement } from 'react';
import type { TwStyle } from 'twin.macro';

import Caret from '~components/Svg/Caret';

import { VARIANT } from '../../enums';

interface IconCardProps {
  variant: VARIANT;
  to: string;
  Icon: ReactElement;
}

const IconCard: React.FC<IconCardProps> = ({ variant, to, Icon, children }) => {
  const baseStyles = [
    // Box
    tw`py-3 px-4 rounded-tr-4xl flex flex-col items-start justify-between space-y-4 w-full max-w-4xl`,
    // Font
    tw`font-bold font-display text-lg md:(text-xl)`,
    // Transition
    tw`transition-colors duration-100 ease-in-out`
  ];

  let colourStyles: TwStyle[] = [];
  switch (variant) {
    case 'green':
      colourStyles = [tw`bg-green text-gray-900 hover:(bg-green-900 text-gray-50)`];
      break;
    case 'blue':
      colourStyles = [tw`bg-blue-700 text-gray-50 hover:(bg-blue-900)`];
      break;
    case 'rose':
      colourStyles = [tw`bg-rose text-gray-50 hover:(bg-rose-800)`];
      break;
    case 'red':
      colourStyles = [tw`bg-red text-gray-50 hover:(bg-red-800)`];
      break;
    case 'orange':
      colourStyles = [tw`bg-orange text-gray-800 hover:(bg-orange-900 text-gray-50)`];
      break;
    case 'yellow':
      colourStyles = [tw`bg-yellow-200 text-gray-800 hover:(bg-yellow-900 text-gray-50)`];
      break;
  }

  return (
    <Link css={[...baseStyles, ...colourStyles]} to={to}>
      {Icon}
      <div tw="flex items-end justify-between space-x-3 w-full">
        <span>{children}</span>
        <Caret tw="w-6 flex-shrink-0 ml-auto" />
      </div>
    </Link>
  );
};

export default IconCard;
